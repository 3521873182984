import { Invoice } from "src/app/core/interfaces/order/order-management/invoice-master";

export class InvoiceModel implements Invoice {
    
    invoiceId:number;
    orderId:number;
    customerId:number;
    shopId:number;
    paymentMode:number; 
    paymentType:string; 
    subtotal:number;
    discountAmount:number; 
    totalTax:number;
    otherCharges:number; 
    actionbyLoginUserId:number; 
    actionbyUserTypeEnumId:number;
    netAmount:number
    grossAmount:number
    totalTaxAmount:number
    totalDiscount:number
    totalTaxable:number
    roundOff:number
    statusEnumId:number;
    sale_discount: number;
    actionOnDate:string;
    invoiceDetail: Array<
                          { 
                            invoice_detail_id : number;
                            product_id : number;
                            dispatch_quantity  : number;
                            per_product_price : number;
                            tax_amount : number;
                            free_no_of_item :number;
                            sale_discount :number;
                            // new parameter add
                            sales_price: Number,
                            trade_discount_per:Number,
                            product_name: string , 
                            product_code: string, 
                            hsn: string,
                            pro_tax_amount: number  ,    
                            tax_per:Number,       
                            t_discount: Number  ,
                            total_discount: Number ,   
                            after_tax_product_amount: Number ,

                            fp_scheme_order_detail: [
                              {
                                scheme_order_id: 0,
                                scheme_id: Number,
                                scheme_amount: Number,
                                scheme_discount_amount:  Number  
                            } ] ,
                          }
                  >


}
  