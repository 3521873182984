
   <div class="row mb-5">
    <div class="col-md-6 ps-0">
        <h2> {{this.company?.companyName }}</h2>
        <ul class="list-group mt-2">
            <li class="list-group-item">{{this.company?.companyAddress}}</li>
            <li class="list-group-item">Phone No. : {{this.company?.contactNumber}}</li>
            <li class="list-group-item">E-mail : {{this.company?.emailId}}</li>
            <li class="list-group-item">GST No :- {{this.company?.gstNumber}}</li>
        </ul>
    </div>
    <div class="col-md-6 text-end pe-0">
        <h2 class="sale-invoice">Sale Invoice</h2>
        <ul class="list-group">
            <li class="list-group-item">
                <h4>Invoice No. #{{invoice.id}}</h4>
            </li>
            <li class="list-group-item">Invoice Date : {{invoice.createdDate | date:'dd-MM-yyyy'}}</li>
        </ul> 
    </div>
    <div class="col-md-12 px-0">
       
    </div>
</div>
<hr>
<!-- DETIAL SECTION -->
<div class="row">
    <div class="col-md-4 ps-0">
        <ul class="list-group">
            <li class="list-group-item">Name & Billing Address of Consignee:</li>
            <li class="list-group-item">
                <h2>{{invoice.customerName}}</h2>
            </li>
            <li class="list-group-item">{{invoice.address}}</li>
            <li class="list-group-item">Phone: {{invoice.mobile}}</li>
            <li class="list-group-item">State : {{invoice.stateName}}</li>
            <li class="list-group-item">GSTIN: {{invoice.gstNumber}}</li>
            <li class="list-group-item">DL No.: </li>
        </ul>
    </div>
    <div class="col-md-4" *ngIf="invoice.orderNameId!=107">      
        <ul class="list-group" *ngIf="invoice.orderTypeStatus !== 'Back Date Invoice'">
            <li class="list-group-item">Name & Shipping Address of Consignee:</li>
           
          
            <li class="list-group-item">
                <h2>{{address.contactPersonName}}</h2>
            </li>
            <li class="list-group-item">{{address.address}}</li>
            <li class="list-group-item">Phone: {{address.mobileNumber}}</li>
            <li class="list-group-item">State : {{address.stateName}}</li>
            <li class="list-group-item">GSTIN: {{invoice.gstNumber}}</li>
            <li class="list-group-item">DL No.: </li>
        </ul>
    </div>
    <div class="col-md-4 pe-0">
        <ul class="list-group">
            <li class="list-group-item">Customer Type : {{invoice.userType}}</li>
            <!-- <li class="list-group-item">
                <h4>Invoice No. #{{invoice.id}}</h4>
            </li>
            <li class="list-group-item">Invoice Date : {{invoice.createdDate | date:'dd-MM-yyyy'}}</li> -->
            <li class="list-group-item mt-3">
                <h4>Order No. : #{{invoice.orderId}} </h4>
            </li>
            <li class="list-group-item">Order Date : {{invoice.orderDate | date:'dd-MM-yyyy'}}</li>
            <li class="list-group-item">Order Type : {{invoice.orderTypeStatus }}</li>

        </ul>
    </div>
</div>
<!-- DETIAL SECTION END -->
<hr>
<!-- TABLE SECTION -->
<div class="invoiceDes table-responsive">
    <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Product Name</th>
            <th scope="col">HSN</th>
            <th scope="col">Qty</th>
            <th scope="col">Free Qty</th>
            <th scope="col">Unit <br/>Price  (₹)</th>
            <th scope="col">Sale <br/>Price  (₹)</th>
            <th scope="col">Scheme <br/>Discount (₹)</th>

            <th scope="col">Trade <br/>Discount (%)</th>
            <th scope="col">Trade <br/>Discount (₹)</th>
            <th scope="col">Total<br/> Discount (₹) </th>


            
            <th scope="col">Taxable <br/>Amount (₹) </th>
            <!-- <th scope="col">CGST%</th>
            <th scope="col">SGST%</th> -->
            <th scope="col">GST (%)</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of tableArray; let i = index;">
            <th scope="row">{{i+1}}</th>
            <td>{{item.productName}}</td>
            <td>{{item.hsnCode}}</td>
            <td>{{item.dispatchQuantity}}</td>
            <td>{{item.freeNoOfItem}}</td>
            <!-- <td *ngIf="isPartner">{{item.distributionBeforeTaxRate}}</td> -->
            <td >{{item.orderUnitPrice |number : '1.2-2'}}</td>
            <td  >{{item.orderAfter_tax_product_amount |number : '1.2-2'}}</td>
            <!-- <td *ngIf="isPartner">{{item.distributionAfterTaxRate}}</td> -->
            
            <td>{{item.orderScheme_discount_amount |number : '1.2-2'}}</td>
            <td>{{item.orderTrade_discount_per}} %</td>  
            <td>{{item.orderT_discount |number : '1.2-2'}}</td>
            <td>{{item.orderTotal_discount |number : '1.2-2'}}</td>
            <!-- <td>{{item.taxable}}</td> -->
            <td>{{item.orde_sales_price * item.dispatchQuantity |number : '1.2-2'}}</td>
            <!-- <td>{{item.gstItem.CGST}}</td>
            <td>{{item.gstItem.SGST}}</td> -->
            <td>{{item.orderTax_per}}</td>
            <td>{{item.orderAfter_tax_product_amount * item.dispatchQuantity |number : '1.2-2'}}</td>

          </tr>

          
        </tbody>
        <tfoot>
          <tr>
              <!-- <td colspan="3" class="text-end">Total</td>
              <td>11</td>
              <td colspan="3" class="text-end">5773</td>
              <td >56349.00</td>
              <td >56349.00</td>
              <td >56349.00</td>
              <td >56349.00</td>

              <td>5773</td>
              <td>5773</td>
              <td >0</td>
              <td >0</td> -->
              <td></td>
    <td></td>
    <td>Total</td>
    <td>{{totalQty}}</td>
    <td>{{totalFreeQty}}</td>
    <td>{{totalBeforeRate |number : '1.2-2'}}</td>
    <td>{{totalAfterRate |number : '1.2-2'}}</td>
    <td></td>
    <td></td>
    <td></td>
    <td>{{this.totalTrader_SchemeDiscount |number : '1.2-2'}}</td>

    <td>{{this.totalTaxableAmount|number : '1.2-2'}}</td>
    <!-- <td>{{(totalBeforTaxAmount *CGST)/100|number : '1.2-2'}}</td>
    <td>{{totalBeforTaxAmount *SGST/100|number : '1.2-2'}}</td> -->
    <td>{{this.totalTaxAmount|number : '1.2-2'}}</td>
    <td>{{this.totalAfterTaxAmount|number : '1.2-2'}}</td>
    

          </tr>
        </tfoot>
      </table>
</div>

<div class="invoiceDes2 table-responsive">
    <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">HSN CODE</th>
            <th scope="col">QTY</th>
            <th scope="col">TAXRATE</th>
            <th scope="col">TAXABLE VALUE</th>
            <th scope="col">SGST</th>
            <th scope="col">CGST</th>
            <th scope="col">IGST</th>
            <th scope="col">TOTAL TAX</th>
            <th scope="col"></th>
            
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item  of invoiceHsnTable">
            <td>{{item.HsnCode}}</td>
            <td>{{item.qty}}</td>
            <td>{{item.taxRate}}</td>
            <td>{{item.taxableValue}}</td>
            <td>{{item.SGST |number : '1.2-2'}}</td>
            <td>{{item.CGST |number : '1.2-2'}}</td>
            <td>{{item.IGST |number : '1.2-2'}}</td>
            <td>{{item.totalTax |number : '1.2-2'}}</td>
            <td></td>
            
            <!-- <td>9405</td>
            <td>50</td>
            <td>115.00</td>                    
            <td>10.00</td>
            <td>11.08</td>
            <td>5750.00</td>                      
            <td>4663.00</td>
            <td>6.00</td>
            <td></td>                        -->
          </tr>
       
        
          <!-- <tr>
              <td colspan="3" class="text-end">Total</td>
              <td colspan="3"></td>
              <td colspan="2" class="text-end">GROSS AMOUNT:</td>
              <td  class="text-end">{{invoice.gross_amount| number : '1.2-2'}}</td>
          </tr> -->

          <tr>
              <td colspan="8">
                  <p>Amt.of Tax Reverse Charges</p>
                  <p  class=""> ₹ : <span [innerHTML]="this.amountIntoWords"></span> Only</p>
                  <p>Note :</p>
                  <div class="bank-detail">
                      <p>Jai Bharat Electric Co.</p>
                   <p>Axis Bank</p> 
                    <p>Branch: Sapna Sangita Road,Indore</p>
                    <p>Current Account: 503010200003414, IFSC Code: UTIB0000503</p>
                  </div>
              </td>
              <td colspan="1" class="text-end pay-last">
                
                <p>GROSS AMOUNT : - {{invoice.gross_amount| number : '1.2-2'}} </p>
                  <p>DISCOUNT : - {{invoice.total_discount |number : '1.2-2'}} </p>
                  <p>TAXABLE AMOUNT : {{invoice.total_taxable|number : '1.2-2'}} </p>
                  <p>CGST : {{(this.isIGST?0:(this.totalTaxAmount/2))|number : '1.2-2'}}</p>
                  <p>SGST : {{(this.isIGST?0:(this.totalTaxAmount/2))|number : '1.2-2'}}</p>
                  <!-- <p>IGST : {{totalBeforTaxAmount *IGST/100|number : '1.2-2'}}</p> -->
                  <p>IGST : {{ (this.isIGST?(this.totalTaxAmount):0)|number : '1.2-2'}}</p>

                 
                  <p>RoundOff : {{roundOff |number : '1.2-2' }}</p>
                  <p class="alert alert-primary netAmount">Net Amount: {{((netAmount |number : '1.2-2'))}}</p>
         

              </td>

          </tr>
        </tbody>
      </table>
</div>

<div class="invoiceFoter d-flex justify-content-between">
     <div class="term" style="font-size: 10px;">
         <p>We declared that this invoice shows the actual price of the goods describe and all particulars are true and correct.</p>
         <p> Goods Ones sold will not be taken back</p>
         <p> No Claim Of Brekage</p>
         <p> Intrest will Be Charged @18% p.a if payment is not made within stipulated time</p>
         <p> Subject To Indore Jurisdiction</p>
     </div>
     <div class="sign">
         <p>For Jai Bharat Elec. Co.</p>
         <br><br>
          <p>  Authorised Signatury</p>
     </div>


</div>
   
