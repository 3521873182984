<!-- © 2021 .Kritin Digital solutions  All rights reserved.
 Original Author: Aman Mishra
 Original Date: 3 June 2021 -->


<mat-toolbar color="primary">
  <button mat-icon-button (click)="burgerClick()" class="burgur_menu">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="verp-spacer"></span>
  <button mat-icon-button class="notificationBtn" aria-label="icon" title="Notifications"
    [matMenuTriggerFor]="notification">
    <mat-icon fontSet="material-icons-outlined" matBadge="5" matBadgeColor="warn">notifications</mat-icon>
  </button>
  <mat-menu #notification="matMenu" class="notificationSection">
    <div class="notificationHeader">
      <h4>Notifications</h4>
    </div>
    <div class="notificationBody">
      <div class="notification">
        <mat-icon>clear</mat-icon>
        <figure class="avatar">
          <img src="../../../assets/images/avatar.png" class="img-fluid" alt="avatar">
        </figure>
        <article class="message">
          <h4>Neelesh Khemawat</h4>
          <p>Your submission has been accepted and you are ready to sign-up for the final assigment which will be ready
            in 2 days</p>
          <span>22 min ago</span>
        </article>
      </div>
      <div class="notification">
        <mat-icon>clear</mat-icon>
        <figure class="avatar">
          <!-- <img src="../../../assets/images/avatar.png" class="img-fluid" alt="avatar"> -->
          <p>P</p>
          <span class="onlineShow"></span>
        </figure>
        <article class="message">
          <h4>Pratibha</h4>
          <p>Your submission has been accepted.</p>
          <span>22 min ago</span>
        </article>
      </div>
      <div class="notification">
        <mat-icon>clear</mat-icon>
        <figure class="avatar">
          <img src="../../../assets/images/avatar.png" class="img-fluid" alt="avatar">
        </figure>
        <article class="message">
          <h4>Avinash</h4>
          <p>Your submission has been accepted and you are ready to sign-up.</p>
          <span>Oct 22, 2:25 PM</span>
        </article>
      </div>
      <span class="viewAll">
        View All <mat-icon>arrow_forward</mat-icon>
      </span>
    </div>
  </mat-menu>

  <span class="vdvider"></span>
  <article class="userDetails">
    <h5>{{user.roleName}}</h5>
    <p>{{adminEmailId}}</p>
  </article>
  <button mat-button [matMenuTriggerFor]="aboveMenu" aria-haspopup="true" mat-button="" class="mat-focus-indicator log">
    <figure class="avatar">
      <img src="../../../assets/images/avatar.png" class="img-fluid" alt="user image">
    </figure>
    <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true"
      data-mat-icon-type="font">expand_more</mat-icon>
    <span class="mat-ripple mat-button-ripple"></span>
    <span class="mat-button-focus-overlay"></span>
  </button>

  <mat-menu #aboveMenu="matMenu" yPosition="above" class="user_menu">
    <button mat-menu-item [routerLink]="['./um/profile/changePassword']">
      <mat-icon>vpn_key</mat-icon> Change Password
    </button>
    <button mat-menu-item (click)="logOut()">
      <mat-icon>logout</mat-icon> Logout
    </button>
  </mat-menu>
</mat-toolbar>