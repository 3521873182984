//===============================================================================
// © 2021 .Kritin Digital solutions  All rights reserved.
// Original Author: Aman Mishra
// Original Date: 3 June 2021
//==============================================================================
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { SharedModule } from './shared/shared.modules';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './core/Interceptor/error-handling-interceptor';
//import { JwtInterceptor } from './core/Interceptor/jwt-interceptor';
import { LoadingInterceptor } from './core/Interceptor/loading-interceptor';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MessagingService } from './core/services/messaging.service';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { UserAccess, UserAccessControlAuthGuard } from './core/guard/user-access-control-auth';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdminAuthGuard } from './core/guard/admin-auth';
import { EmployeeGuard } from './core/guard/employee.guard';
import { AuthInterceptor } from './core/services/interceptors/auth.interceptor';

/** Http interceptor providers  */
export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
 // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },

];


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    AngularEditorModule ,
    FormsModule,
    ReactiveFormsModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NgxSpinnerModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase),  
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    interceptorProviders,
    MessagingService, 
    UserAccessControlAuthGuard, 
    UserAccess,
    AdminAuthGuard, 
    EmployeeGuard ],
  bootstrap: [AppComponent]
})
export class AppModule { }
