<section class="main-body-confirm">
  <div class="rowj">
   <button mat-icon-button class="colseBtn">
     <mat-icon (click)="onNoClick()">close</mat-icon>
 </button>
  </div>
  <mat-card class="p-0  tab_table">
    <app-table [cols]="cols" [data]="tableData"  (onRowAction)="onRowActioHandler($event)">
    </app-table>
  </mat-card>

</section>