import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private spinner: NgxSpinnerService,
        private authService: AuthService,
        private toaster: ToastrService
      ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>>  {
   // this.spinner.show();
    const token = sessionStorage.getItem('authToken'); 
    if(token){
        return next.handle(req.clone({setHeaders: { Authorization: `${token}` } }))
    }
    return next.handle(req)
    .pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              const res: any = event?.body;
            
              if (res.success && res?.message) {
               // this.spinner.hide();
                this.toaster.success(res?.message);
                   
               } else{
                this.authService.logOut();
               }
              // else if (!res?.success) {
              //    this.toaster.error("efwewetewrewr")//,res?.message);
              // }
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            
            console.log("error.error",error)
            let errorMessage = 'Something went wrong.';
            if (error.error instanceof ErrorEvent) {
              // Client-side error
              errorMessage = `Error: ${error.error.message}`;
              this.authService.logOut();
            } else {
              // Server-side error
              errorMessage = error?.error?.error || errorMessage;
              this.authService.logOut();
            }
            
            if (error.status === 401) {
              this.toaster.error('Please login again', 'Session Expired');
              this.authService.logOut();
            } else {
              this.toaster.error(errorMessage);
              //this.authService.logOut();
            }
          
            return throwError(error);
          })

         
    )
  }

 

}
