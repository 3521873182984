
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from '../interfaces/common/common-response';
import { IHistoryData } from '../interfaces/common/history-response';
import { ToastrService } from 'ngx-toastr';
import { NUMBER_TYPE } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private http: HttpClient, private toastr : ToastrService) { }
  serverEndPoint = environment.apiUrl;


  uploadImage(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const url = this.serverEndPoint + 'fileUpload';
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  deleteImage(fileName): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'fileDelete';
    return this.http.post<ICommonResponse>(url, fileName)
  }
  getHistoryDetail(masterName: string, masterId: Number): Observable<IHistoryData> {
    const url = this.serverEndPoint + 'getMasterRemarkByMasterNameAndMasterId?masterName=' + masterName + '&mastersId=' + masterId;
    return this.http.get<IHistoryData>(url)
  }

  getDateFormat(data) {
    const date = new Date(data)
    console.log('date', date)
    return date;
    // return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  }
 
  // getDateFormat(date) {
  //   return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  // }

  convertYear(date:Date) {
    const formattedDate =  date.getFullYear();
    return formattedDate
  }

  
  convertDate(date: any) {
    if (date ===  undefined){
      date = '';
    }
    if (date !== ''){
      if (date !== null && typeof(date) !== 'string') {
        if (date._d !== undefined){
          date =  date._d;
        }
        const formattedDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
        return formattedDate;
      }
    }
  }
  //  convertDate(date:Date) {
  //    console.log('date', date)
  //   // const formattedDate =  date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  //    return ""  
  // }

  checkUserAccessControlForNavBar(page) {
    let userAccessData = JSON.parse(sessionStorage.getItem('userAccessData'));
    if(userAccessData == null) {
      return true;
    }
    let flag = false;
    if(userAccessData.find(x => x.module == page).isSelected === true) {
      flag = true;
    }
    return flag;
  }
  checkUserAccessControlForNavItem(page, item) {
    let userAccessData = JSON.parse(sessionStorage.getItem('userAccessData'));
    if(userAccessData == null) {
      return true;
    }
    let flag = false;
    let pageData = userAccessData.find(x => x.module == page).data;
    let itemData = pageData.find(x => x.page_name == item);
    if(itemData.add || itemData.view || itemData.edit || itemData.delete) {
      flag = true;
    }
    return flag;
  }
  checkUserAccessControlForAction(module, page, action) {
    let userAccessData = JSON.parse(sessionStorage.getItem('userAccessData'));
    if(userAccessData == null) {
      return true;
    }
    let pageData = userAccessData.find(x => x.module == module).data;
    let itemData = pageData.find(x => x.page_name == page);
    return itemData[action]

  }

  
  
  getEnumType(Type: string): Observable<any> {
    const url = this.serverEndPoint + 'GetEnumDetail?Enum_type=' + Type ;
    return this.http.get<any>(url)
  }


  // CHECK MRP IS GREATER
  checkMRPIsSmall(value:any) {
    let MRP: any = Number(value?.enterMrp)
    
    let isSmall:any =false
    // console.log(value, MRP > value.afterTaxRate, MRP > value.distributionAfterTaxRate)
    // console.log(MRP , value.distributionAfterTaxRate,value.afterTaxRate)
    // check all parameter
    if((MRP > Number(value.afterTaxRate) ) &&  (MRP > Number(value.distributionAfterTaxRate))) {
     isSmall=true

    }    
    else{

     this.toastr.warning(MRP,"MPR Must be High" )
      isSmall=false
    }
    return isSmall
  }
}
