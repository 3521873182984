//===============================================================================
// © 2021 .Kritin Digital solutions  All rights reserved.
// Original Author: Aman Mishra
// Original Date: 3 June 2021
//==============================================================================


import { Component, OnInit, } from '@angular/core';
import { DataShareService } from 'src/app/core/services/data-sharing.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  sidebar = true
  user :any;
  adminEmailId:any;

  constructor(public dataSharingService: DataShareService, public router: Router) { }

  ngOnInit(): void {
    this.user = JSON.parse(sessionStorage.getItem('user'))
   // console.log('user',this.user)
    this.adminEmailId = this.user.emailId


  }

  burgerClick() {
    this.sidebar = !this.sidebar
    this.dataSharingService.sidebarCollapsed(this.sidebar)
  }

  logOut() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

}
