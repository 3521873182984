import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeGuard implements CanActivate {
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
    
    userData:any=JSON.parse(sessionStorage.getItem('user'))
  constructor(private router: Router) { 
  }
  canActivate(route: ActivatedRouteSnapshot,
       state: RouterStateSnapshot): Observable<any> | boolean{
        console.log(route, state, this.router)
    if (sessionStorage.getItem('user')) {
      console.log(this.userData)
      if(this.userData?.userEnumId == 31){ //employee
        // http://localhost:4000/#/main/order/order-management
        this.router.navigate(['./main/order/order-management/packing-card']);
        return true;
      }else{
        return false
      }
    }
    this.router.navigate(['login']);
    return false;
  }
  
}
