
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AdminAuthGuard implements CanActivate {
  constructor(private router: Router) { }
  userData:any=JSON.parse(sessionStorage.getItem('user'))

  canActivate() {
    if (sessionStorage.getItem('user')) {
      if (sessionStorage.getItem('user')) {
        console.log(this.userData)
        if(this.userData?.userEnumId == 3){ //employee
          return true;
        }else{
          return false
        }
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }
}
}
